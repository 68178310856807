/*
 * @Description:
 * @Author: huyue
 * @Date: 2024-12-30 10:59:05
 * @LastEditors: huyue
 * @LastEditTime: 2024-12-31 22:04:37
 */
import { registerMicroApps, start } from 'qiankun'
import { filterApps, microConfig } from './registerApp'
// 微应用注册
export const registerApps = microApps => {
  const _apps = filterApps(microApps)
  registerMicroApps(_apps, microConfig)
  start({
    prefetch: true,
    singular: true,
    sandbox: true,
    excludeAssetFilter: item => item.includes('api.map.baidu')
  })
}
